<template>
	<input
		:id="id"
		type="checkbox"
		:disabled="disabled"
		:name="name"
		:required="required"
		:value="modelValue"
		:class="[
			'relative text-transparent focus:outline-none focus:ring-0 size-6 cursor-pointer appearance-none p-2',
			'before:absolute before:bottom-1/2 before:right-1/2 before:size-6 before:translate-x-1/2 before:translate-y-1/2 before:border before:border-sg-grey-200 before:duration-300 checked:before:border-primary',
			'after:absolute after:bottom-1/2 after:right-1/2 after:size-3 after:translate-x-1/2 after:translate-y-1/2 after:rounded-full after:bg-sg-grey-200 after:opacity-0 after:duration-300 checked:after:bg-primary checked:after:opacity-100 hover:after:opacity-100',
		]"
		:checked="modelValue"
		@change="onChange($event)"
	/>
</template>

<script setup lang="ts">
export interface CheckboxProps {
	disabled?: boolean;
	required?: boolean;
	defaultValue?: boolean;
	id?: string;
	name?: string;
	modelValue: boolean;
}

withDefaults(defineProps<CheckboxProps>(), {
	id: undefined,
	name: undefined,
	size: 'lg',
});

const emit = defineEmits(['update:modelValue']);

function onChange(event: Event) {
	emit('update:modelValue', (event.target as HTMLInputElement).checked);
}
</script>
